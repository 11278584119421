import { Route, Routes } from "react-router-dom";
import { PageNotFound } from "../components/page_not_found/PageNotFound";
import { ViewPort } from "../components/viewport/ViewPort";
import { RoutePaths } from "./constants";

export const RouterConfig = () => {
  return (
    <Routes>
      <Route path={RoutePaths.VIEWPORT} element={<ViewPort />} />
      <Route path={RoutePaths.PAGE_NOT_FOUND} element={<PageNotFound />} />
    </Routes>
  );
};
