import { IconButton, Tooltip } from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import { Auth } from "aws-amplify";

export const FloatingMenu = (props: any) => {
  const handleSignout = async (event: any) => {
    await Auth.signOut();
    const currentEnv =
      process.env.REACT_APP_ENV === undefined
        ? "dev"
        : process.env.REACT_APP_ENV;
    window.location.href = "https://www." + currentEnv + ".dimdocks.com";
  };

  return (
    <div className="floating-menu">
      <Tooltip title="Sign out" placement="left-start">
        <IconButton
          color="error"
          aria-label="sign out"
          component="span"
          onClick={handleSignout}
        >
          <ExitToApp />
        </IconButton>
      </Tooltip>
    </div>
  );
};
