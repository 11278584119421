import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { Grid, Button } from "@mui/material";
import { Amplify } from "aws-amplify";
import awsConfig from "./aws-exports";
import logo from "./assets/svg/wsp.svg";
import { BrowserRouter } from "react-router-dom";
import { RouterConfig } from "./navigation/RouterConfig";

//Amplify Configuration
Amplify.configure(awsConfig);
Amplify.configure({
  awsConfig,
  Auth: {
    cookieStorage: {
      domain:
        process.env.REACT_APP_DOMAIN === undefined
          ? ".dimdocks.com"
          : process.env.REACT_APP_DOMAIN,
      expires: 1,
      path: "/",
      secure: true,
    },
  },
});

const components = {
  Header() {
    return (
      <Grid textAlign="center">
        <img alt="Amplify logo" src={logo} height="60" width="60" />
      </Grid>
    );
  },

  Footer() {
    return <Grid textAlign="center">WSP &copy; All Rights Reserved</Grid>;
  },

  SignIn: {
    Header() {
      return (
        <Grid
          textAlign={"center"}
          sx={{ fontWeight: "bold", m: 1, fontSize: "20px" }}
        >
          Sign In to your account
        </Grid>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();
      return (
        <Grid container sx={{ justifyContent: "flex-end", px: 3, py: 1 }}>
          <Button
            onClick={toResetPassword}
            style={{
              color: "var(--amplify-components-button-link-color)",
              fontWeight: "normal",
              fontSize: "var(--amplify-components-button-small-font-size)",
            }}
          >
            Forgot your password
          </Button>
        </Grid>
      );
    },
  },
};

function App() {
  return (
    <div className="App">
      <Authenticator components={components} hideSignUp>
        {() => (
          <BrowserRouter>
            <RouterConfig />
          </BrowserRouter>
        )}
      </Authenticator>
    </div>
  );
}

export default App;
